<template>
    <div>

        <h1 class="float-left">Créances:</h1>
        <h1 class="float-right"><b>{{ total.toFixed(2) }}</b> €</h1>
        <br/>
        <br/>

        <vs-row>
            <vs-col vs-xs="12" vs-sm="12" vs-lg="12">
                <div class="grid-layout-container alignment-block">
                    <vs-row>
                        <vs-col vs-w="3" vs-xs="12" vs-sm="6">
                            <div class="btn_special"  v-bind:style="getBtnEffet('essai',1)" v-on:click="btnSwitchData(1)">
                                <span>-30 jours: </span>
                                <span class="float-right">{{ stats.nb_30 }}</span>
                                <h3 class="text-center">{{ millier(stats.t_30.toFixed(2)) }} €</h3>
                            </div>
                            <br/>
                        </vs-col>
                        <vs-col vs-w="3" vs-xs="12" vs-sm="6">
                            <div class="btn_special" v-bind:style="getBtnEffet('actif',2)" v-on:click="btnSwitchData(2)">
                                <span>+30 jours: </span>
                                <span class="float-right">{{ stats.nb30 }}</span>
                                <h3 class="text-center">{{ millier(stats.t30.toFixed(2)) }}  €</h3>
                            </div>
                            <br/>
                        </vs-col>
                        <vs-col vs-w="3" vs-xs="12" vs-sm="6">
                            <div class="btn_special" v-bind:style="getBtnEffet('suspendu',3)" v-on:click="btnSwitchData(3)">
                                <span>+60 Jours: </span>
                                <span class="float-right">{{ stats.nb60 }}</span>
                                <h3 class="text-center">{{ millier(stats.t60.toFixed(2)) }}  €</h3>
                            </div>
                            <br/>
                        </vs-col>
                        <vs-col vs-w="3" vs-xs="12" vs-sm="6">
                            <div class="btn_special" v-bind:style="getBtnEffet('inactif',4)" v-on:click="btnSwitchData(4)">
                                <span>+120 Jours: </span>
                                <span class="float-right">{{ stats.nb120 }}</span>
                                <h3 class="text-center">{{ millier(stats.t120.toFixed(2)) }}  €</h3>
                            </div>
                            <br/>
                        </vs-col>
                    </vs-row>
                </div>
            </vs-col>
        </vs-row>

        <!-- table -->
        <vx-card>
            <vs-table multiple v-model="selected" pagination max-items="20" :data="members" noDataText="Aucune créance">
                
                <!-- header table -->
                <template slot="thead">
                    <!--<vs-th sort-key="uid" class="f_center">Photo</vs-th>-->
                    <vs-th sort-key="first_name">Prénom Nom</vs-th>
                    <vs-th sort-key="dif_init">Dif. Initial</vs-th>
                    <vs-th sort-key="recu">Total Reçus</vs-th>
                    <vs-th sort-key="reste">Reste à payer</vs-th>
                    <vs-th sort-key="day">Pas de reçu<br/>depuis (jours)</vs-th>
                    <!--<vs-th sort-key="uid">Statut</vs-th>-->
                </template>

                <!--Body table-->
                <template slot-scope="{data}">
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td :data="data[indextr].first_name">
                            <span v-on:click="selectMbr(data[indextr].uid)">{{ StrCapital(data[indextr].first_name+' '+data[indextr].last_name) }}</span>
                        </vs-td>
                        <vs-td :data="data[indextr].dif_init">
                            <span v-on:click="selectMbr(data[indextr].uid)">{{ data[indextr].dif_init.toFixed(2) }}</span>
                        </vs-td>
                        <vs-td :data="data[indextr].recu">
                            <span v-on:click="selectMbr(data[indextr].uid)">{{ data[indextr].recu.toFixed(2) }}</span>
                        </vs-td>
                        <vs-td :data="data[indextr].reste">
                            <span v-on:click="selectMbr(data[indextr].uid)">{{ data[indextr].reste.toFixed(2) }}</span>
                        </vs-td>
                        <vs-td :data="data[indextr].day">
                            <span v-on:click="selectMbr(data[indextr].uid)">{{ data[indextr].day }}</span>
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
        </vx-card>
    </div>
</template>



<style lang="scss" scoped >
	.btn_special
	{
		font-weight: bold;
        margin-bottom:0px;
		margin-right:5px;
		padding:20px;
		padding-left:40px;
		padding-right:40px;
		color:white;
		border-radius: 5px;
		font-size:1.2em;
		cursor:pointer;

		border:2px;
		span{
			text-align:left;
		}
		b{
			float:right;
		}
        h3{
            margin-top:10px;
            color:#666666;
        }
	}
</style>

<script>

import autoRefresh from '@/assets/utils/autoRefresh'

import Utils from '@/assets/utils/utils';
import Member from '@/database/models/member';
import Credit from '@/assets/utils/credit';

export default {
    data(){
        return{
            selected:[],
            members:[],
            members_cache:[],


            stats:{
                t_30:0,
                t30:0,
                t60:0,
                t120:0,

                nb_30:0,
                nb30:0,
                nb60:0,
                nb120:0,
            },
            total:0,
            btn_select:0,
        }
	},
	mounted:function()
	{
        //autoRefresh
		autoRefresh.SetCallback(()=>{
			this.buildCreance();
        })
        autoRefresh.refresh()
    },
    watch:{
        $route (){
            
        }
    },
    methods:{
        millier:Utils.Millier,
        StrCapital:function(str){
            return Utils.StrCapital(str);
        },
        getBtnEffet( name, filtre )
		{
			if(this.btn_select != filtre)
				return { "background-color":"white",'color':Member.color[name] };
			return { "background" : 'linear-gradient(120deg, '+Member.color[name]+','+Member.color[name]+','+Member.color[name]+','+Member.color['f'+name]+')' };
		},
		getBtnSelectEffet()
		{
			if(this.buffer_mbr_etat)
				return { 'background-color':'#493cc9 !important' };
        },
        
        selectMbr( uid )
		{
			this.$router.push('/admin/member/'+uid);
		},
        buildCreance()
        {
            this.selected = [];
            this.members = [];

            this.total = 0;

            let tab = [];
            Member.dataForAll(async (mbr , end)=>
            {
                let rep = await Credit.calcCreance( mbr.uid );
                let creance = (rep.dif-rep.recu);

                if(creance != 0)
                {
                    //day calcule
                    let day = "Jamais";
                    if(rep.date!=null)
                    {
                        let t = (new Date()).getTime() - rep.date.getTime();
                        day = ((t/1000)/60/60/24)>>0;
                    }

                    //tab
                    tab.push({
                        uid       : mbr.uid,
                        first_name: mbr.first_name,
                        last_name : mbr.last_name,
                        dif_init  : rep.dif,
                        recu      : rep.recu,
                        reste     : creance,
                        day       : day,
                    })
                    this.total += creance;

                    //stats
                    if(day<31 || day=="Jamais")
                    {
                        this.stats.t_30 += creance;
                        this.stats.nb_30++;
                    }
                    else if( day>30 && day<61)
                    {
                        this.stats.t30 += creance;
                        this.stats.nb30++;
                    }
                    else if( day>60 && day<121)
                    {
                        this.stats.t60 += creance;
                        this.stats.nb60++;
                    }
                    else
                    {
                        this.stats.t120 += creance;
                        this.stats.nb120++;
                    }
                }
                end();
            })
            .then(()=>{
                this.members = tab;
                this.members_cache = tab;
            });
        },


        btnSwitchData( filtre )
		{
            this.members = [];

            //Désactiver filtre et bouton
            if(filtre==0 || (this.btn_select==filtre))
            {
                this.btn_select = 0;
                this.members = this.members_cache;
                return;
            }
            this.btn_select = filtre;


            //Triage
            for( var i=0; i<this.members_cache.length; i++)
            {
                let buffer = this.members_cache[i];
                let day = buffer.day;

                //stats
                if(day<31 || day=="Jamais")
                {
                    if(filtre==1)
                        this.members.push( buffer );
                }
                else if( day>30 && day<61)
                {
                    if(filtre==2)
                        this.members.push( buffer );
                }
                else if( day>60 && day<121)
                {
                    if(filtre==3)
                        this.members.push( buffer );
                }
                else
                {
                    if(filtre==4)
                        this.members.push( buffer );
                }
            }
		},
    }
};

</script>